import { ref } from 'vue';
import { useStore } from 'vuex';
import API from 'Services/API';
import UtilsCookies from 'CommonUtils/cookies';
import UtilsLocalStorage from 'CommonUtils/localStorage';

const ServiceAPI = new API('/api/vuestore');

const useEciExpress = () => {
  const isLoading = ref(false);
  const error = ref({});
  const result = ref({});
  const store = useStore();
  
  const isFireFlyCart = computed(() => store.getters['cart/getIsFireFlyCart']);
  const hasZip = computed(() => !!store.getters['cart/getZip']);
  const eciExpress = computed(() => store.getters['cart/getEciExpress']);
  const orderAttemptId = computed(() => store.getters['cart/getOrderAttemptId']);
  const clickAndCar = computed(() => store.getters['cart/getClicAndCar']);

  const getUpdateTimeSlotsHandler = ({ createFor }) => {
    if (isFireFlyCart.value) return { handler: Service.patchTimeSlotsInOrderDelivery.bind(Service) };
    if (createFor === 'eci_express') return { handler: Service.postEciExpress.bind(Service) };
    if (createFor === 'click_and_car') return { handler: Service.postClickAndCar.bind(Service) };
    return { handler: async () => {} }
  }
  
  const getUpdateTimeSlotsBody = ({ createFor, slotTimeValue }) => {
    const timeSlotFirefly = {
      end_date: slotTimeValue.end_date,
      start_date: slotTimeValue.start_date,
      is_immediate_delivery: slotTimeValue.immediate ?? false
    };
  
    const time_slot = {
      end_date: slotTimeValue.end_date,
      start_date: slotTimeValue.start_date,
    }
  
    const orderDeliveryId = createFor === 'eci_express'
      ? eciExpress.value?.order_delivery_identifier
      : clickAndCar.value?.order_delivery_identifier;
  
    const fireflyBody = {
      time_slot: timeSlotFirefly,
      order_attempt_id: orderAttemptId.value,
      order_delivery_id: orderDeliveryId
    }
  
    if (isFireFlyCart.value) return { body: fireflyBody };

    return { body: { time_slot } }
  }

  const setZipCookie = (postalCode) => {
    const domain = document.location.host
      .split('.')
      .reverse()
      .reduce(function (a, b, c) {
        if (c === 1) a = '.' + b + '.' + a;
        return a.split(':')[0];
      });
    const path = '/';
    UtilsCookies.setCookie('zip', postalCode, 90, domain, path);
  };

  const handleEciExpressPostalCode = async (postal_code) => {
    isLoading.value = true;
    error.value = {};

    const isPatchFireFlyCart = isFireFlyCart.value && hasZip.value;
    
    let updateEciExpressBindedFn;
    if (isFireFlyCart.value) {
      const fireflyBody = {
        postal_code,
        order_attempt_id: orderAttemptId.value,
        order_delivery_id: eciExpress.value?.order_delivery_identifier
      };
      if (isPatchFireFlyCart) updateEciExpressBindedFn = Service.patchEciExpressInOrderDelivery.bind(Service, fireflyBody);
      else updateEciExpressBindedFn = Service.setEciExpressInOrderDelivery.bind(Service, fireflyBody);
    } else {
      updateEciExpressBindedFn = Service.postEciExpress.bind(Service, { postal_code });
    }

    return await updateEciExpressBindedFn()
      .then((res) => {
        setZipCookie(postal_code);
        result.value = res;
        isLoading.value = false;
        store.dispatch('cart/setEciExpress', res.eci_express);
        if (res?.order_attempt_id) store.dispatch('cart/setOrderAttemptId', res.order_attempt_id);
        return {
          ok: true,
          data: res.eci_express,
          error: null,
        };
      })
      .catch(err => {
        console.log(err)
        error.value = err;
        isLoading.value = false;
        return {
          ok: false,
          error: err,
        };
      });

    // Hacer el patch
  };

  const handleEciExpressTimeSlot = async (slotTimeValue) => {
    isLoading.value = true;
    error.value = {};
    const { handler } = getUpdateTimeSlotsHandler({ createFor: 'eci_express' });
    const { body } = getUpdateTimeSlotsBody({ createFor: 'eci_express', slotTimeValue });
    return await handler(body)
      .then((res) => {
        result.value = res;
        store.dispatch('cart/setEciExpress', res.eci_express);
        isLoading.value = false;

        return {
          ok: true,
          error: null,
        };
      })
      .catch((err) => {
        error.value = err;
        isLoading.value = false;
        return {
          ok: false,
          error: err,
        };
      });
  };

  const handleClickNCarCenter = async (general_centre, company) => {
    isLoading.value = true;
    error.value = {};

    let updateCncBindedFn;
    if (isFireFlyCart.value) {
      const hasCnc = Object.keys(clickAndCar.value).length;
      const fireflyBody = {
        center: {
          general_centre,
          company,
        },
        order_attempt_id: orderAttemptId.value,
        order_delivery_id: clickAndCar.value?.order_delivery_identifier,
      };
      if (hasCnc) {
        updateCncBindedFn = Service.updatePickupStoreInOrderDelivery.bind(Service, fireflyBody);
      } else {
        updateCncBindedFn = Service.setClickAndCarInOrderDelivery.bind(Service, fireflyBody);
      }
    } else {
      // OMS
      updateCncBindedFn = Service.postClickAndCar.bind(Service, { general_centre });
    }

    return await updateCncBindedFn()
      .then((res) => {
        result.value = res;
        if (res?.order_attempt_id) store.dispatch('cart/setOrderAttemptId', res.order_attempt_id);
        store.dispatch('cart/setClickNCar', res.click_and_car);
        isLoading.value = false;
        return {
          ok: true,
          data: res.click_and_car,
          error: null,
        };
      })
      .catch((err) => {
        error.value = err;
        isLoading.value = false;
        return {
          ok: false,
          error: err,
        };
      });
  };

  const handleClickNCarTimeSlot = async (slotTimeValue) => {
    isLoading.value = true;
    error.value = {};

    const { handler } = getUpdateTimeSlotsHandler({ createFor: 'click_and_car' });
    const { body } = getUpdateTimeSlotsBody({ createFor: 'click_and_car', slotTimeValue });

    return await handler(body)
      .then((res) => {
        result.value = res;
        store.dispatch('cart/setClickNCar', res.click_and_car);
        isLoading.value = false;
        return {
          ok: true,
          error: null,
        };
      })
      .catch((err) => {
        error.value = err;
        isLoading.value = false;
        return {
          ok: false,
          error: err,
        };
      });
  };

  const resetState = async () => {
    await store.dispatch('cart/resetClickCarTimeSlots');
    await store.dispatch('cart/resetEciExpressTimeSlots');
  };

  const handleEciExpressPostalCodeLS = async (postalCode) => {
    // Llamo al servicio de centros según postal code
    isLoading.value = true;
    error.value = {};
    return await ServiceAPI
      .getCentersByPostalCode(postalCode)
      .then((res) => {
        isLoading.value = false;
        const data = UtilsLocalStorage.updateJSON('EciExpress', res);
        store.dispatch('cart/setEciExpress', data);
        return {
          ok: true,
          data,
          error: null,
        };
      })
      .catch((err) => {
        console.log(err);
        return {
          ok: false,
          data: null,
          error: err
        };
      }); 
  };
  
  const handleEciExpressTimeSlotLS = (slotTimeValue) => {
    // Guardo en local storage
    isLoading.value = true;
    error.value = {};
    const time_slot = {
      end_date: slotTimeValue.end_date,
      start_date: slotTimeValue.start_date
    };
    const data = UtilsLocalStorage.updateJSON('EciExpress', time_slot);
    result.value = data;
    store.dispatch('cart/setEciExpress', data);
    isLoading.value = false;
    return {
      ok: true,
      data, 
      error: null
    };
  };

  const handleClickNCarCenterLS = (general_centre, company, name, food_centre) => {
    // Guardo en local storage
    isLoading.value = true;
    error.value = {};
    const data = UtilsLocalStorage.updateJSON('ClickNCar', { general_centre, company, name, food_centre });
    result.value = data;
    store.dispatch('cart/setClickNCar', data);
    isLoading.value = false;
    return {
      ok: true,
      data,
      error: null
    };
  };
  
  const handleClickNCarTimeSlotLS = (slotTimeValue) => {
    // Guardo en local storage
    isLoading.value = true;
    error.value = {};
    const time_slot = {
      end_date: slotTimeValue.end_date,
      start_date: slotTimeValue.start_date
    };
    const data = UtilsLocalStorage.updateJSON('ClickNCar', time_slot);
    result.value = data;
    store.dispatch('cart/setClickNCar', data);
    isLoading.value = false;
    return {
      ok: true,
      data,
      error: null
    };
  };

  /**
  const getEciExpressLS = () => {
    // Obtengo los centros, código postal y timeslot de ECI Express
  };


  const getClicNCarLS = () => {
    // Obtengo los centros y el timeslot de ClickNCar
  };
  **/

  return {
    isLoading,
    handleEciExpressPostalCode,
    handleEciExpressPostalCodeLS,
    handleEciExpressTimeSlot,
    handleEciExpressTimeSlotLS,
    handleClickNCarCenter,
    handleClickNCarCenterLS,
    handleClickNCarTimeSlot,
    handleClickNCarTimeSlotLS,
    resetState,
  };
};

export default useEciExpress;
